<template>
  <div class="container">
    <a-empty v-if="commentsList.length < 1"><span>没有发表评论哦</span></a-empty>
    <div class="commentsListArea" v-if="commentsList.length > 0">
      <div v-for="(item, index) in commentsList" :key="index" class="commentItem">
        <h2 class="b-title" @click="goBlog(item)">{{item.title}}</h2>
        <p class="content">{{item.content}}</p>
        <div class="attr">
          <span class="attrItem"><calendar-outlined />{{item.pubTime}}</span>
          <span class="attrItem"><heart-filled />{{item.likes}}</span>
        </div>
      </div>
      <a-pagination 
        v-if="pagination.total>0"
        style="margin-top: 50px;"
        :total="pagination.total" 
        show-quick-jumper
        :show-total="total => `总共 ${total} 条评论`" 
        :pageSize="pagination.size" 
        @change="changePage"/>
    </div>
  </div>
</template>
<script>
  import * as CMT from '../../api/comment.js';
  import { HeartFilled, CalendarOutlined, } from '@ant-design/icons-vue';
  export default {
    name: 'MyCmtList',
    components: {
      HeartFilled,
      CalendarOutlined
    },
    data() {
      return {
        commentsList: [],
        pagination: {
          current: 1,
          size: 10,
          total: 0,
        }
      }
    },
    mounted() {
      // console.log('my cmt list', this.$route)
      this.loadUserCmtList(this.$route.params.pid, 1, 10)
    },
    methods: {
      loadUserCmtList(uid, page, size) {
         let params = new FormData()
         params.append('uid', uid)
         params.append('page', page)
         params.append('size', size)
         CMT.loadUserCmtsByUId(params).then(res=>{
          //  console.log('usr cmt', res)
           if(res.data.code === 200) {
             this.commentsList = res.data.data.records
             this.pagination.total = res.data.data.total
             this.pagination.current = res.data.data.current
             this.pagination.size = res.data.data.size
           }
         })
      },
      changePage(page) {
        this.loadUserCmtList(this.$route.params.id, page, 10)
      },
      goBlog(item) {
        this.$router.push({
          path: '/blog',
          query: {
            id: item.bid
          }
        })
      }
    },
  }
</script>

<style scoped>
  .container {
    width: 640px;
    height: 100%;
  }
  .commentsListArea {
    width: 500px;
  }
  .commentItem {
    text-align: left;
    margin: 20px 0;
    width: 650px;
    height: 150px;
    background-color: rgb(252, 252, 252);
    border-radius: 10px;
    padding: 1px 10px;
  }
  .b-title {
    color: rgb(65, 55, 55);
  }
  .b-title:hover {
    color: rgb(157, 157, 246);
  }
  .content {
    width: 100%;
    font-size: 16px;
    padding: 3px 10px;
    border-radius: 20px;
    background-color: rgb(225, 224, 224);
    height: 60px;
    color: rgb(103, 103, 103);
  }
  .attr {
    text-align: left;
  }
  .attrItem:not(:first-child) {
    margin-left: 12px;
  }
</style>