import axios from 'axios';
import { URLS } from './allurl.js';

export function loadBlogCmtsByBid(params) {
  const url = URLS.CMT.BLOG_CMT_LIST.url;
  return axios({
    method: 'post',
    url: url,
    data: params,
  });
}

export function writeBlogCmt(params) {
  const url = URLS.CMT.WRITE_CMT.url;
  return axios({
    method: 'post',
    url: url,
    data: params,
  });
}

export function renderCmtLikeList(params) {
  return axios({
    method: 'post',
    url: URLS.CMT.CMT_LIKES_LIST.url,
    data: params,
  });
}

export function loadUserCmtsByUId(params) {
  return axios({
    method: 'post',
    url: URLS.CMT.SRH_CMT_BY_UID.url,
    data: params,
  });
}

export default { loadBlogCmtsByBid, writeBlogCmt, renderCmtLikeList, loadUserCmtsByUId }